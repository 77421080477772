import { useState } from "react";
import ReactModal from "react-modal";
import { Formik, Form, Field,} from 'formik';
import HOST from "../../service/host";
import fetchProducts from "../../service/productApi";


const SetProduct = ({product}) =>{
    const[showModal,setShowModal]=useState(false);
    const[newImg,setNewImg]=useState(false);
    const[buttonsArr, setButtonsArr]=useState(product.buttonsArr);
    let formData = new FormData();
   
    let initialValues={};
    const inputArr=[];
    for (let key in product) {
        if (product.hasOwnProperty(key)){
          const value = product[key];
          initialValues[key] = value;
          if(key === '_id' || key === 'category' || key === 'categoryUA'){
            inputArr.push(
                <li className="flex gap-2" key={Math.random()}>
                    <label className="w-[200px]" htmlFor={key}>{key} : </label>
                    <Field className='w-[400px] border border-solid border-[grey] rounded px-2 text-center'  as='input' disabled='active' id={key} name={key} placeholder={value}/>
                </li>)
          }else if(key === 'imgId') {}
          else if(key === 'description' || key === 'descriptionEN'){
            inputArr.push(
                <li className="flex gap-2" key={Math.random()}>
                    <label className="w-[200px]" htmlFor={key}>{key}</label>
                    <Field className='w-[400px] h-[200px] border border-solid border-[grey] rounded px-2 text-center'  as='textarea'  id={key} name={key} placeholder={value}/>
                </li>)
          }else if(key === 'buttonsArr'){
            inputArr.push(
                <li className=" flex justify-between" key={Math.random()}>
                    <label className="w-[200px]" >{key}</label>
                    <div className="w-[400px] border-2 ">
                        {Array.isArray(buttonsArr) && buttonsArr.map((item, index)=>{
                            return(
                                <div className="flex justify-center gap-4">
                                    <input className="w-[50px]" type="number" placeholder={item} defaultValue={item} onBlur={(e)=>setButtonsArr((prev)=>{
                                        const newArr= [...prev];
                                        console.log(e.target.valueAsNumber)
                                        newArr.splice(index, 1, e.target.valueAsNumber)
                                        return newArr
                                    })}/>
                                    {Array.isArray(buttonsArr) && buttonsArr.length > 1 &&
                                        <button onClick={() =>setButtonsArr((prev) => {
                                            const newArr = [...prev]; // Создаем копию массива
                                            newArr.splice(index, 1);  // Удаляем элемент
                                            return newArr;            // Возвращаем новый массив
                                        })}> <img src="/cancel.svg" alt="cancel" width={10} /> </button>
                                    }
                                </div>
                        )})}
                        {Array.isArray(buttonsArr) && buttonsArr.length < 3 &&
                            <button className="w-[110px] my-2 h-8 mt-5 mx-auto flex justify-center items-center text-[12px] text-white opacity-80 hover:opacity-100 rounded-[10px] font-semibold bg-[#0E8388]" onClick={()=>setButtonsArr((prev)=>[...prev, 228])}> Добавить кнопку </button>
                        }
                        {!Array.isArray(buttonsArr) &&
                            <button onClick={()=>setButtonsArr([228])}> Добавить кнопку </button>
                        }
                    </div>
                </li>)
          }
          else{
            inputArr.push(
                <li className="flex gap-2" key={Math.random()}>
                    <label className="w-[200px]" htmlFor={key}>{key}</label>
                    <Field className='w-[400px] border border-solid border-[grey] rounded px-2 text-center'  as='input'  id={key} name={key} placeholder={value}/>
                </li>)
          }
        }
      }
    
    function setImg(e) {
        const files = Array.from(e.target.files);
        const filesArr=[];   
        files.forEach((item)=>{
            filesArr.push(item)
           })
        setNewImg(filesArr)
        
      }
      
    
    async function handleSubmit(values){
        if(product.buttonsArr !== buttonsArr){values.buttonsArr = buttonsArr}
        // console.log(product)
          console.log(values)
        
        for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
                const element = values[key];
                initialValues[key] !== element && key !== "imgId" && formData.append(key,element);
              
                if(newImg && key === "imgId" && newImg.length > 0){
                    formData.append('initialImgArr',[...initialValues.imgId])

                    newImg.forEach((element) => {
                        formData.append('imgArr', element) 
                    });
                }
            }
        }
        for (const [key, value] of formData.entries()) {
            console.dir(`${key}: ${value} ${typeof value}`);
          }
        
        const formValuesIterator = formData.values();
        const firstValue = formValuesIterator.next().value;
        
         firstValue !== undefined  &&  await fetchProducts.put(product._id,formData).then((res)=>alert(res)).catch((req)=>alert(req));
        // window.location.reload();
       
    }
    
    return(
        <>
            <button onClick={()=>setShowModal(true)} className=" hover:bg-neutral-400 p-2 rounded-xl">
                <img src="/change.svg" width={20} height={20} alt="Change Product"/>
            </button>

            <ReactModal 
                isOpen={showModal}
                contentLabel="Delete Product"
                ariaHideApp={false}
                
            >
                <button className="ml-auto mr-0 block" onClick={()=>setShowModal(false)}><img  src='/cancel.svg' alt="Cancel" width={20} height={20}/></button>
                    <Formik
                    initialValues={initialValues}
                    // validate={validateForm}
                    onSubmit={handleSubmit}
                    >{({ isSubmitting }) => (
                        <Form>
                            <ul className=" flex flex-col gap-2 items-center">
                                {inputArr}
                            </ul>
                            <ul className={` my-7 flex justify-center gap-4 flex-wrap ${newImg && newImg.length > 0 && 'opacity-50'} `}>
                                {initialValues.imgId.map((item,index)=><li key={Math.random()}>
                                        <img src={`${HOST}/api/products/img/${item}`} className='w-[300px] h-[300px] border-[1px] border-[grey]' alt="REq" />
                                    <div className="mt-4 text-center">
                                    </div>
                                </li>)}

                            </ul>
                            {newImg && newImg.length > 0 && 
                            <>
                                <img className="w-[100px] h-[200px] mx-auto" src="/arrow-down.svg" alt="Arrow-Down" />
                                <ul className=" my-7 flex justify-center gap-4 flex-wrap">
                                    {newImg.map((item)=>{ 
                                        return <img key={Math.random()} src={URL.createObjectURL(item)} className='w-[300px] h-[300px] border-[1px] border-[grey]' alt="REq" />
                                    })}
                                </ul>
                            </>      
                            }
                                    <input className="" type="file" multiple name={`img`} id={`img`} onChange={(e)=>{setImg(e)}}/>
                            <button className="w-[300px] h-12 mt-5 mx-auto flex justify-center items-center text-[19px] text-white opacity-80 hover:opacity-100 rounded-[10px] font-semibold bg-[#0E8388]" type="submit" disabled={isSubmitting}>
                                Відправити
                            </button>
                        </Form>
                    )}
                </Formik>     
            </ReactModal>
        </>
    )
}
export default SetProduct;